#header-user-dropdown {
  button {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    background-color: transparent !important;
    border-width: 0 !important;

    span {
      color: white !important;
    }

    img {
      background-color: #e4e6e7;
      padding: 4px;
    }
  }
}
