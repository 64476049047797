.returnLink {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 44px;
    text-decoration: none;
}

.mainContentContainer {
    padding: 15px;
}

.riderFilterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 25px;
}

.filterContainer p {
    opacity: 0.5;
    font-size: 14px;
    margin: 4px;
}

.filterContainer p:hover {
    cursor: pointer;
}

.activeFilter {
    opacity: 1 !important;
}