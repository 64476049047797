.liveRace {
    border: solid red 2px;
}

.raceItem {
    border-radius: 5px;
    padding: 5px 15px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}