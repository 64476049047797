@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

html {
    font-family: 'Poppins', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
    min-height: 100vh;
}

.lightText {
    color: #7af0f5 !important;
}

* {
    box-sizing: border-box;
}

a {
    color: #8c8c8c;
    text-decoration: none;
}

a.active {
    color: white;
}


.blob {
    border-radius: 50%;
    margin: 10px;
    height: 100px;
    width: 100px;
    box-shadow: 0 0 0 0 rgb(122, 240, 245);
    position: absolute;
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(9, 49, 51, 0.63);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(122, 240, 245, 0.63);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}


body {
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.navItem {
    color: white;
    margin-top: 5px;
    font-size: 25px;
    margin-left: 30px;
    text-align: center;
}

.navItem.active {
    color: #7AF0F5FF;
}

.navItem:hover {
    color: #d1fbff
}
